import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  host: string  = environment.api_url;

  constructor(private http:HttpClient) { }

  login(data){
    console.log('service', data);
    var formData = new FormData();
    Object.entries(data).forEach(entry => {
      const ent:any = entry;
      const [key, value] = ent;
      formData.append(key, value);
    });
    return this.http.post(this.host + 'src/login.php', formData);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('id');
    // Check whether the token is expired and return
    // true or false
    return token ? true : false;
  }
}
