import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-full-login',
  templateUrl: './full-login.component.html',
  styleUrls: ['./full-login.component.css']
})
export class FullLoginComponent implements OnInit {


  loginForm: FormGroup;
  send: boolean;

  constructor(private _myservice: AuthService, private router: Router){
    this.loginForm = new FormGroup({
      user: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });
  }

  isValid(controlName) {
    return this.loginForm.get(controlName).invalid && this.loginForm.get(controlName).touched;
  }



  ngOnInit() {
    console.log(localStorage.getItem('intrnaet-rol'));
  }

  validateKey(event){
    console.log('event', event)
    if (event.keyCode === 13) {
      this.login();
    }
  }

  login(){
    this.send = true;
    console.log('localStorage.getItem(key)', localStorage.getItem('id'));
    if (this.loginForm.valid) {
      // this._myservice.login({user: 'mauricio.barrios', password: 'buenos123456'})
      this._myservice.login(this.loginForm.value)
        .subscribe(
          data => {
            console.log('login ************************************************************************************', data);
            if(data['error']){
              alert('Se presento un error por favor intente de nuevo');
            } else {
              let payload = data['payload'];
              console.log('payload *************************************++', payload.length, payload);
              if(payload.length){
                localStorage.setItem('id', payload[0].id.toString());
                localStorage.setItem('intrnaet-rol', payload[0].rol.toString());
                this.router.navigate(['']);
              } else
                alert('Usuario o Contraseña incorrecta');
            }
            this.send = false;
          },
          error => {
            alert('Se presento un error por favor intente de nuevo.');
            this.send = false;
          }
        );
      console.log('final');
    } else {
      this.send = false;
    }
    // localStorage.setItem('token', 'asasa');
    // localStorage.setItem('intrnaet-rol', 'ADMIN');
  }



}
