import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentsService } from '../../services/documents.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css']
})
export class LinksComponent implements OnInit {

  links = [];

  constructor(private documentsService: DocumentsService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.documentsService.fetctLinks().subscribe(liks => {
      if(liks.error){
        if(liks.status == 401){
          localStorage.clear();
          this.router.navigate(['./login']);
        } else
          this.toastr.error('Error', 'Se ha presentado un error, por favor intentelo de nuevo');
      }else
        this.links = liks.payload;
    }, error => {
      this.toastr.error('Error', 'Se ha presentado un error, por favor intentelo de nuevo');
    })
  }

}
